import Vue from "vue";
import { mdiAccount, mdiCellphone, mdiClose, mdiMonitorDashboard, mdiPlusCircleOutline, mdiWeb } from "@mdi/js";
import { Articulo, DetalleArticulo, Paso } from "apd.sos.faq.models";
import { formatISO } from "date-fns";
import { ApiSosFaq, AxiosHttpClient, ApiErrorResponse } from "apd.apiconnectors";
import { ApiList, getUrl } from "@/config/apiUrls";
import snackbarModule from "@/store/modules/snackbarModule";
import spinnerModule from "@/store/modules/spinnerModule";

export default Vue.extend({
  name: "NuevoArticuloComponent",
  data() {
    return {
      icons: {
        PDApp: mdiCellphone,
        PDWeb: mdiWeb,
        Kioskos: mdiMonitorDashboard,
        General: mdiAccount,
        add: mdiPlusCircleOutline,
        cerrar: mdiClose,
      } as Record<string, string>,
      dialog: false,
      categoria: 0,
      guia: false as boolean,
      articulo: {} as Articulo,
      detalleArray: [] as DetalleArticulo[],
      detalle: "",
      titulo: "",
      subtitulo: undefined,
      pasos: [] as Paso[],
    };
  },
  computed: {
    rules() {
      return this.$store.state.validationRules;
    },
    disabled: function (): boolean {
      return this.categoria != 0 &&
        this.titulo != undefined &&
        this.titulo != "" &&
        this.detalle != "" &&
        this.detalle != undefined
        ? false
        : true;
    },
  },
  methods: {
    guiaPasos() {
      if (!this.guia) {
        this.pasos = [];
      } else {
        this.pasos.push({
          id: 0,
          descripcion: "",
          imgUrl: "",
          idDetalle: 0,
          posicion: 1,
          created: formatISO(Date.now()),
          updated: formatISO(Date.now()),
        });
      }
    },
    add() {
      this.pasos.push({
        id: 0,
        descripcion: "",
        imgUrl: "",
        idDetalle: 0,
        posicion: this.pasos.length + 1,
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
      });
    },
    remove(index: number) {
      this.pasos.splice(index, 1);
      if (this.pasos.length <= 0) {
        this.guia = false;
      }
    },
    crear() {
      this.detalleArray.push({
        id: 0,
        idArticulo: 0,
        descripcion: this.detalle,
        posicion: 1,
        subtitulo: this.subtitulo,
        paso: this.pasos,
        created: formatISO(Date.now()),
        updated: formatISO(Date.now()),
      });
      this.articulo.detalleArticulo = this.detalleArray;

      this.articulo.id = 0;
      this.articulo.idCategoria = parseInt(this.categoria.toString());
      if (this.guia) {
        this.articulo.idTipoArticulo = 1;
      } else {
        this.articulo.idTipoArticulo = 2;
      }
      this.articulo.titulo = this.titulo;

      this.articulo.autor =
        this.$store.getters["oidcStore/oidcUser"].family_name +
        " " +
        this.$store.getters["oidcStore/oidcUser"].given_name;
      this.articulo.created = formatISO(Date.now());
      this.articulo.updated = formatISO(Date.now());
      this.guardar();
    },
    guardar() {
      const clienteFaq = new AxiosHttpClient(getUrl(ApiList.SosFaq, this.$store.getters.appMode));
      clienteFaq.AddBearer(this.$store.getters["oidcStore/oidcAccessToken"]);
      const apiFaq = new ApiSosFaq(clienteFaq);
      apiFaq
        .RegistrarArticulo(this.articulo)
        .then((e) => {
          spinnerModule.Show;
          if (e != undefined) {
            snackbarModule.ShowSuccess("Articulo creado");
            this.articulo = e as Articulo;
            this.vaciar();
          }
        })
        .catch((error) => {
          const x = error as ApiErrorResponse;
          x.apiError.mensajeUsuario;
          snackbarModule.Show(x.apiError.mensajeUsuario);
        })
        .finally(() => {
          spinnerModule.Hide;
        });
    },
    vaciar() {
      this.dialog = false;
      this.pasos = [];
      this.detalleArray = [];
      this.titulo = "";
      this.detalle = "";
      this.subtitulo = undefined;
    },
    getIcon(icono: string) {
      return "icons." + icono;
    },
  },
});
